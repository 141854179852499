import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fields', 'resetFields', 'switch', 'blockingLayer']

  connect () {
    this.switchTarget.addEventListener('change', this.updateElements)

    this.updateElements()
  }

  disconnect () {
    this.switchTarget.removeEventListener('change', this.updateElements)
  }

  updateElements = () => {
    this.fieldsTargets.forEach(field => {
      field.disabled = this.switchTarget.checked
    })

    this.resetFieldsTargets.forEach(field => {
      field.disabled = !this.switchTarget.checked
    })

    if (this.switchTarget.checked) { this.blockingLayerTarget.classList.add('show') } else { this.blockingLayerTarget.classList.remove('show') }
  }
}
