import { Controller } from '@hotwired/stimulus'
import Toastify from 'toastify-js'

export default class extends Controller {
  static values = {
    text: { type: String, default: 'a text' },
    destination: { type: String, default: '' }
  }

  connect () {
    Toastify({
      text: this.textValue,
      duration: 7500,
      destination: this.destinationValue,
      newWindow: true,
      close: true,
      gravity: 'top', // `top` or `bottom`
      position: 'right', // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: '#c0c'
      },
      onClick: function () {} // Callback after click
    }).showToast()

    this.element.remove()
  }
}
