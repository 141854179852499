import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'
import { Popover } from 'bootstrap'
import { CommonDOMRenderer } from 'render-jsx/dom'
import * as Routes from '../../javascript/routes'
import { I18n } from 'i18n-js'
import translations from '../../javascript/locales.json'
import { parameterizeString } from '../../javascript/src/helpers'

const i18n = new I18n(translations)
i18n.locale = 'de'

export default class extends Controller {
  static targets = ['tagList', 'addTagButton']

  connect () {
    this.categories = []
    this.loadCategories()

    this.tagify = new Tagify(this.tagListTarget, {
      addTagOnBlur: false,
      dropdown: {
        position: 'text',
        enabled: 1 // show suggestions dropdown after 1 typed character
      },
      duplicates: false,
      enforceWhitelist: false,
      templates: {
        tag: this.renderTag
      },
      transformTag: (tagData) => {
        tagData.name = tagData.value
      },
      trim: true
    })

    this.tagify.on('input', this.tagsChanged).on('edit:input', this.tagsChanged)

    this.popover = new Popover(this.addTagButtonTarget, {
      content: this.popoverContent,
      html: true,
      container: 'body'
    })
  }

  renderTag (tagData, tagify) {
    const renderer = new CommonDOMRenderer()

    return renderer.render(
      <tag
        title={tagData.title || tagData.value}
        contenteditable='false'
        spellcheck='false'
        tabIndex={this.settings.a11y.focusableTags ? 0 : -1}
        class={[this.settings.classNames.tag, tagData.matchingType]}
        {...this.getCustomAttributes(tagData)}
      >
        <x title='' class={this.settings.classNames.tagX} role='button' aria-label='remove tag' />
        <div>
          <span class={[this.settings.classNames.tagText, 'tag', `tag-category-${parameterizeString(tagData.category)}`]}>
            {tagData[this.settings.tagTextProp] || tagData.value}
          </span>
        </div>
      </tag>
    ).target.outerHTML
  }

  /* eslint-disable react/jsx-key */
  popoverContent = () => {
    const renderer = new CommonDOMRenderer()

    return renderer.render(
      <div>
        <div class='row'>
          {['mandatory', 'optional', 'excluded'].map(matchingType => {
            return (
              <div class='col d-flex flex-column'>
                {i18n.t(`activerecord.attributes.tagging.matching_types.${matchingType}`)}

                {this.categories.map(({ value, name }) => {
                  return (
                    <a
                      class={`tag tag-${matchingType} tag-category-${parameterizeString(value)} badge mb-1 text-decoration-none cursor-pointer`}
                      onClick={() => this.addTag(matchingType, value)}
                    >
                      {name}
                    </a>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    ).target
  }

  addTag (matchingType, category) {
    this.popover.hide()
    this.tagify.addEmptyTag({ matchingType, category })
  }

  /**
   * Loads matching tags from the server on user input (new tags and edited tags)
   * @param e
   */
  tagsChanged = (e) => {
    this.tagify.whitelist.length = 0 // reset current whitelist
    this.tagify.loading(true).dropdown.hide.call(this.tagify) // show the loader animation

    const { matchingType, category } = e.detail.tag.__tagifyTagData
    const inputValue = e.detail.data.newValue

    this
      .loadMatchingTags(category, inputValue)
      .then(tags => {
        const tagObjects = tags.map(tag => {
          return { category: tag.category, matchingType, value: tag.name, name: tag.name }
        })

        // Add the newly loaded tags to the dropdown suggestions and keep the data
        // the user already added.
        this.tagify.whitelist.push(...tagObjects, ...this.tagify.value)
        this.tagify.loading(false).dropdown.show.call(this.tagify, e.detail.data.newValue)
      })
  }

  loadMatchingTags = (category, input) => {
    return fetch(Routes.admin_tags_path({ category, q: input, format: 'json' }))
      .then(response => response.json())
  }

  loadCategories () {
    fetch(Routes.categories_admin_tags_path({ format: 'json' }))
      .then(response => response.json())
      .then(data => {
        this.categories = data
      })
  }
}
