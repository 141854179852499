import { Controller } from '@hotwired/stimulus'
import '@snaque/snaque-bite/snaque-bite'

export default class extends Controller {
  static targets = ['bite']
  static values = {
    bite: Object
  }

  connect () {
    this.biteTarget.bite = this.biteValue
  }

  disconnect () {
  }
}
