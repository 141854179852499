import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = ['submitButton']

  initialize () {
    this.handleInput = debounce(this.handleInput, 250).bind(this)
  }

  connect () {
    this.element.addEventListener('input', this.handleInput)
  }

  disconnect () {
    this.element.removeEventListener('input', this.handleInput)
  }

  handleInput () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click()
    } else {
      this.element.requestSubmit()
    }
  }
}
