import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'trigger']
  static values = { collapsed: { type: Boolean, default: false } }

  connect () {
    this.element.classList.add('collapsible')
    this.triggerTarget.classList.add('collapsible-trigger')
    this.triggerTarget.addEventListener('click', (event) => { this.toggle(event) })
    this.containerTarget.classList.add('collapsible-container')
    this.perform()
  }

  toggle () {
    this.collapsedValue = !this.collapsedValue
    this.perform()
  }

  perform () {
    if (this.collapsedValue) {
      this.element.classList.add('collapsed')
      this.containerTargets.forEach(target => {
        target.style.display = 'none'
      })
    } else {
      this.element.classList.remove('collapsed')
      this.containerTargets.forEach(target => {
        target.style.display = 'block'
      })
    }
  }
}
