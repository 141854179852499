import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['kiosk']
  static values = {
    project: { type: String, default: 'PREVIEW_PROJECT' },
    abortUrl: String,
    snaquebarId: String
  }

  connect () {
    this.kioskTarget.addEventListener('kioskFailure', console.error)

    this.kioskTarget.addEventListener('kioskAbort', event => {
      if (this.hasAbortUrlValue) {
        window.location.href = this.abortUrlValue
      }
    })
    this.kioskTarget.addEventListener('kioskSuccess', (event) => {
      const currentURL = new URL(window.location.href)
      currentURL.searchParams.delete('snaquebar_id')

      window.confirm('Freischaltung erfolgreich!')

      window.location.href = currentURL
    })

    this.kioskTarget.project = this.projectValue
    this.kioskTarget.resource = 'preview_resource'
    this.kioskTarget.meta = JSON.stringify({ snaquebar_id: this.snaquebarIdValue })
    this.kioskTarget.variant = 'preview'

    this.kioskTarget.showKiosk = true
  }
}
