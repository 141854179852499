import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['messageInput']

  connect () {
    this.messageInputTarget.value = ''
    this.messageInputTarget.focus()
  }

  checkSubmit (event) {
    if (event.keyCode === 13 && event.metaKey) {
      event.preventDefault()
      this.messageInputTarget.closest('form').requestSubmit()
    } else {
      this.adjustHeight()
    }
  }

  adjustHeight () {
    this.messageInputTarget.style.height = 'auto'
    this.messageInputTarget.style.height = (this.messageInputTarget.scrollHeight) + 'px'
  }
}
