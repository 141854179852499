import { Controller } from '@hotwired/stimulus'
import { CommonDOMRenderer } from 'render-jsx/dom'
import Picker from 'vanilla-picker'

export default class extends Controller {
  static targets = ['input']

  inputTargetConnected (element) {
    this.initializePicker(element)
  }

  generateTrigger = (input) => {
    const renderer = new CommonDOMRenderer()
    return renderer.render(
      <div class='color-picker-trigger' style={`background-color: ${input.value}`} />
    ).target
  }

  initializePicker (input) {
    const trigger = this.generateTrigger(input)
    input.insertAdjacentElement('afterend', trigger)

    const picker = new Picker({
      parent: trigger,
      alpha: false,
      color: input.value
    })

    /*
        You can do what you want with the chosen color using two callbacks: onChange and onDone.
    */
    picker.onChange = function (color) {
      trigger.style.background = color.rgbaString
      input.value = color.hex.substring(0, 7)
      input.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }
}
