import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

/**
 * Attaches a cleave input for money values to an input
 */
export default class extends Controller {
  static targets = ['input']

  connect () {
    // We cannot simply use {swapHiddenInput: true} as it throws off Money parsing.
    // There might be a way to get Money to parse e.g. "1.00" correctly, currently it thinks that
    // "00" is a currency...
    this.dummyInput = this.#createDummyInput()
    this.inputTarget.type = 'hidden'
    this.inputTarget.id = ''
    this.element.appendChild(this.dummyInput)

    new Cleave(this.dummyInput, { // eslint-disable-line no-new
      numeral: true,
      numeralIntegerScale: 8,
      numeralDecimalScale: 4,
      numeralDecimalMark: ',',
      numeralPositiveOnly: true,
      delimiter: '.',
      onValueChanged: (e) => {
        this.inputTarget.value = e.target.rawValue.replace('.', ',')
      }
    })
  }

  #createDummyInput () {
    const dummy = this.inputTarget.cloneNode()
    dummy.name = ''

    return dummy
  }
}
