import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['tabContent', 'tabContentFieldset', 'tabHeaderActions']

  connect () {
    this.element.addEventListener('show.bs.tab', this.handleTabShow)
    this.disableInputFieldsInAllTabs()
    this.hideAllHeaderActions()
    this.setActiveTabFromLocationHash()
    this.showTabHeaderActions(this.activeTab)
    this.activateInputFields(this.activeTab)
  }

  disconnect () {
    this.element.removeEventListener('show.bs.tab', this.handleTabShow)
  }

  handleTabShow = (event) => {
    const newTab = this.element.querySelector(event.target.dataset.bsTarget)
    const tabURL = new URL(window.location.href)
    tabURL.searchParams.set('active_tab', event.target.dataset.bsTarget.slice(1))

    this.disableInputFieldsInAllTabs()
    this.hideAllHeaderActions()
    this.showTabHeaderActions(newTab)
    this.activateInputFields(newTab)
    // Disabling/Enabling fields should be counted as an input event / a user action
    // and therefore trigger corresponding behaviour, e.g. a preview update
    this.element.dispatchEvent(new Event('input', { bubbles: true }))

    // Set the location hash to the active tab for page refreshes
    Turbo.navigator.history.push(tabURL)
  }

  get activeTab () {
    return this.tabContentTargets.find(target => target.classList.contains('active'))
  }

  setActiveTabFromLocationHash () {
    const tabURL = new URL(window.location.href)
    const activeTabParam = tabURL.searchParams.get('active_tab')

    if (activeTabParam) {
      const activeTabID = `#${activeTabParam}`
      const tabFromHash = this.element.querySelector(activeTabID)

      // If the correct tab is already marked as active, we have nothing left to do
      if (tabFromHash && !tabFromHash.classList.contains('active')) {
        // Mark the correct tab pill as active
        this.element.querySelector('.nav-link.active')?.classList?.remove('active')
        this.element.querySelector(`.nav-link[data-bs-target="${activeTabID}"]`).classList.add('active')

        // Mark the correct tab pane as active
        this.tabContentTargets.forEach(pane => pane.classList.remove('active'))
        tabFromHash.classList.add('active')
      }
    }
  }

  activateInputFields = (element) => {
    this.tabContentFieldsetTargets.filter(fieldset => element.contains(fieldset))[0].removeAttribute('disabled')
  }

  disableInputFieldsInAllTabs = () => {
    this.tabContentFieldsetTargets.forEach(fieldset => fieldset.setAttribute('disabled', true))
  }

  showTabHeaderActions = (tab) => {
    const actions = this.tabHeaderActionsTargets.find(el => el.dataset.tabId === tab.id)

    if (actions) {
      actions.style.display = 'inherit'
    }
  }

  hideAllHeaderActions = () => {
    this.tabHeaderActionsTargets.forEach(target => { target.style.display = 'none' })
  }
}
