import { Controller } from '@hotwired/stimulus'

/**
 * Simple controller to insert text into an a text field or text area.
 * inputTarget is the field the text should be inserted into,
 * the text has to be specified as action param, e.g. data-insert-text-value-param="the text"
 */
export default class extends Controller {
  static targets = ['input']

  insert (event) {
    this.inputTarget.setRangeText(event.params.value)
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }
}
