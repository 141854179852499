import { Controller } from '@hotwired/stimulus'

/**
 * Very simple controller that dispatches an event with window as target.
 * Useful if you'd like to communicate with a controller that's not a parent of the current one.
 */
export default class extends Controller {
  dispatchEvent = ({ params: { eventName, detail } }) => {
    this.dispatch(eventName, { target: window, detail, prefix: false })
  }
}
