import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  hide () {
    this.element.classList.remove('is-active')
  }

  show = (e) => {
    if (e.detail.id === this.uniqueID) {
      this.element.classList.add('is-active')
    }
  }

  get uniqueID () {
    return this.element.id
  }
}
