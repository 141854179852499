import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tabContent', 'tabContentFieldset', 'tabHeaderActions']

  connect () {
    // If there are multiple tabs in a form, we handle them as mutually exclusive,
    // meaning that we don't want form fields from a different tab to be submitted
    // with the form. Therefore, we disable them (resp. a fieldset around them)
    // whenever a tab is changed.
    this.#disableInputFieldsInAllTabs()
    this.#activateInputFieldsForActiveTab()
    this.#emitInputEvent()
  }

  activateTab ({ target, params: { tab } }) {
    this.element.querySelector('.tab.is-active').classList.remove('is-active')
    this.element.querySelector('.tab-content.is-active').classList.remove('is-active')

    // Mark the tab header as active
    target.closest('.tab').classList.add('is-active')
    // Mark the tab content as active
    this.tabContentTargets.find(content => content.id === tab)?.classList?.add('is-active')

    // If there are multiple tabs in a form, we handle them as mutually exclusive,
    // meaning that we don't want form fields from a different tab to be submitted
    // with the form. Therefore, we disable them (resp. a fieldset around them)
    // whenever a tab is changed.
    this.#disableInputFieldsInAllTabs()
    this.#activateInputFieldsForActiveTab()
    this.#emitInputEvent()
  }

  get activeTab () {
    return this.element.querySelector('.tab-content.is-active')
  }

  #emitInputEvent () {
    // Changing a tab should be handled like any other input inside a form
    this.element.dispatchEvent(new Event('input', { bubbles: true }))
  }

  #activateInputFieldsForActiveTab () {
    this.tabContentFieldsetTargets.filter(fieldset => this.activeTab.contains(fieldset))[0].removeAttribute('disabled')
  }

  #disableInputFieldsInAllTabs () {
    this.tabContentFieldsetTargets.forEach(fieldset => fieldset.setAttribute('disabled', ''))
  }
}
