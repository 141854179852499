import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['colorFieldTemplate', 'colorField', 'preview']
  static values = {
    fieldName: String,
    colors: Array
  }

  connect () {
    this.colorsValue.forEach(color => {
      const node = this.templateNode()
      node.value = color
      this.element.appendChild(node)
    })

    this.generatePreview()
  }

  updateColors () {
    this.colorsValue = this.colorFieldTargets.map(elem => elem.value)
    this.generatePreview()
  }

  generatePreview () {
    this.previewTarget.style.backgroundImage = `linear-gradient(-45deg,${this.colorsValue.join(',')})`
  }

  templateNode () {
    const node = this.colorFieldTemplateTarget.content.querySelector('input').cloneNode(true)
    node.id = node.id + this.colorFieldTargets.length.toString()
    return node
  }
}
