// src/application.js
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
const context = require.context('.', true, /\.jsx?$/)
const contextComponents = require.context('../../components', true, /_controller\.jsx?$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)
