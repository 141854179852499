import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapsedItem', 'showItemsLink']
  connect () {
    if (this.hasShowItemsLinkTarget) {
      this.showItemsLinkTarget.addEventListener('click', () => { this.showCollapsedItems() })
    }
  }

  disconnect () {
    if (this.hasShowItemsLinkTarget) {
      this.showItemsLinkTarget.removeEventListener('click', this.showCollapsedItems)
    }
  }

  showCollapsedItems () {
    this.collapsedItemTargets.forEach(elem => {
      elem.classList.remove('is-collapsed')
    })
    this.showItemsLinkTarget.classList.add('is-collapsed')
  }
}
