import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'trigger']

  connect () {
    this.triggerTarget.addEventListener('click', this.showConfirm)
  }

  disconnect () {
    this.triggerTarget.removeEventListener('click', this.showConfirm)
  }

  async confirm (content) {
    return new Promise((resolve, reject) => {
      resolve(window.confirm(content))
    })
  }

  showConfirm = async (event) => {
    if (await this.confirm(this.confirmText) === false) { event.preventDefault() }
  }

  get confirmText () {
    return this.templateTarget.content.textContent.trim()
  }
}
