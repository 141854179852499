import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'

export default class extends Controller {
  static targets = ['dateField']

  connect () {
    this.picker = flatpickr(this.dateFieldTarget, {
      altInput: true,
      mode: 'single',
      locale: German,
      enableTime: true,
      dateFormat: 'Z',
      minDate: this.dateFieldTarget.dataset.minimum,
      altFormat: 'd.m.Y - H:i'
    })
  }
}
