import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapseButton', 'collapseButtonIcon']
  static values = {
    iconCollapsed: { type: String, default: 'left_panel_open' },
    iconOpen: { type: String, default: 'left_panel_close' },
    autoCollapseBreakpoint: { type: Number, default: 1215 }
  }

  connect () {
    if (this.hasCollapseButtonTarget) {
      this.collapseButtonTarget.addEventListener('click', this.#toggleSidebar)
      window.addEventListener('resize', this.#handleResize)
      this.isCollapsed = false
      this.#handleResize()
    }
  }

  #handleResize = () => {
    if (window.matchMedia(`(max-width: ${this.autoCollapseBreakpointValue}px)`).matches) {
      this.#collapse()
    } else {
      this.#show()
    }
  }

  #toggleSidebar = () => {
    this.isCollapsed ? this.#show() : this.#collapse()
  }

  #collapse () {
    this.element.classList.add('sidebar-collapsed')
    this.element.classList.remove('sidebar-open')
    this.collapseButtonIconTarget.textContent = this.iconCollapsedValue
    this.isCollapsed = true
  }

  #show () {
    this.element.classList.remove('sidebar-collapsed')
    this.element.classList.add('sidebar-open')
    this.collapseButtonIconTarget.textContent = this.iconOpenValue
    this.isCollapsed = false
  }
}
