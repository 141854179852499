import { Popover } from 'bootstrap'

/**
 * Automatically hide open popovers if the user clicks outside one.
 */
document.querySelector('body').addEventListener('click', e => {
  if (!e.target.closest('.popover') && !e.target.closest('.popover-trigger')) {
    document.querySelectorAll('.popover').forEach(elem => {
      Popover.getInstance(elem).hide()
    })
  }
})
