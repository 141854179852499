import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

/**
 * Refreshes the turbo frame it is assigned to periodically
 */
export default class extends Controller {
  static values = { interval: { type: Number, default: 60 } }

  connect () {
    this.element.addEventListener('turbo:submit-end', this.handleSubmitEnd)
    document.addEventListener('turbo:frame-missing', this.handleMissingFrame)
  }

  disconnect () {
    this.element.removeEventListener('turbo:submit-end', this.handleSubmitEnd)
    this.element.removeEventListener('turbo:frame-missing', this.handleMissingFrame)
  }

  handleMissingFrame = (e) => {
    e.preventDefault()
  }

  handleSubmitEnd = (e) => {
    // For now, we assume that redirects will only happen on successful form submissions
    if (!e.detail.success) { return }

    // For some forms, there might be other submission targets, e.g. for the bite preview.
    // In that case, we never want to leave the page.
    if (e.detail.formSubmission.location.href !== e.target.action) { return }

    // Only redirect if the server actually expects us to
    if (e.detail.fetchResponse.response.redirected) {
      Turbo.visit(e.detail.fetchResponse.response.url)
    }
  }
}
