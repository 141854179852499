import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

/**
 * Attaches a cleave input for percentage values to an input
 */
export default class extends Controller {
  connect () {
    new Cleave(this.element.querySelector('input'), { // eslint-disable-line no-new
      numeral: true,
      numeralIntegerScale: 3,
      numeralDecimalScale: 2,
      numeralDecimalMark: ',',
      numeralPositiveOnly: true,
      delimiter: '.'
    })
  }
}
