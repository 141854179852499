import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'container', 'item', 'positionField', 'addItemTrigger']
  static values = {
    // The maximum amount of items per type, -1 means no restrictions
    maxAmountPerType: { type: Number, default: -1 },
    // The maximum total amount of nested items, -1 means no restrictions
    maxAmount: { type: Number, default: -1 }
  }

  add (event) {
    event.preventDefault()

    const template = this.template(event.params.type)
    const content = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())

    this.containerTarget.insertAdjacentHTML('afterbegin', content)

    this.updatePositionFields()

    this.element.dispatchEvent(new Event('input', { bubbles: true }))
  }

  remove (event) {
    event.preventDefault()

    const item = this.itemTargets.filter(i => i.contains(event.target))[0]

    if (item.dataset.newRecord === 'true') {
      item.remove()
    } else {
      item.querySelector('input[name*=\'_destroy\']').value = 'true'
      item.style.display = 'none'
    }

    this.updatePositionFields()
    this.adjustAddItemTriggers()

    this.element.dispatchEvent(new Event('input', { bubbles: true }))
  }

  updatePositionFields () {
    if (this.hasPositionFieldTarget) {
      this.positionFieldTargets.forEach((field, idx) => {
        field.value = idx
      })
    }
  }

  itemTargetConnected (item) {
    this.adjustAddItemTriggers()
    this.hideIfMarkedForDestruction(item)
  }

  /**
   * Initially hides an element if its _destroy field is already set to "true".
   * This is usually the case if a form is re-rendered due to validation errors.
   *
   * @param item
   */
  hideIfMarkedForDestruction (item) {
    if (this.isMarkedForDestruction(item)) { item.style.display = 'none' }
  }

  isMarkedForDestruction (item) {
    return item.querySelector('input[name*=\'_destroy\']')?.value === 'true'
  }

  /**
   * Adjusts the visibility of all addItemTrigger elements based
   * on whether it should be possible to add more items of the corresponding type.
   * This happens on initialization and whenever an item is added or removed
   */
  adjustAddItemTriggers () {
    this.addItemTriggerTargets.forEach(trigger => {
      const type = trigger.dataset.nestedFormTypeParam

      if (this.mayAddItemOfType(type)) { trigger.style.display = 'block' } else { trigger.style.display = 'none' }
    })
  }

  /**
   *
   * @param type
   * @returns {Boolean} +true+ if another item of the given type may be added
   */
  mayAddItemOfType (type) {
    const totalAmount = this.itemTargets.filter(item => !this.isMarkedForDestruction(item)).length
    const amountOfType = this.itemsOfType(type).filter(item => !this.isMarkedForDestruction(item)).length

    if (this.maxAmountValue !== -1 && (totalAmount + 1 > this.maxAmountValue)) { return false }
    if (this.maxAmountPerTypeValue !== -1 && (amountOfType + 1 > this.maxAmountPerTypeValue)) { return false }

    return true
  }

  /**
   *
   * @param type
   * @returns {*} The items matching the given type
   */
  itemsOfType (type) {
    return this.itemTargets.filter(i => i.dataset.nestedFormType === type)
  }

  /**
   *
   * @param type
   * @returns {*} The template for the given type
   */
  template (type) {
    return this.templateTargets.filter(target => target.dataset.type === type)[0]
  }
}
