import { Controller } from '@hotwired/stimulus'
import { Dropzone } from 'dropzone'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['fileField', 'preview', 'progressBar']

  connect () {
    Turbo.session.connectStreamSource(this.element)

    this.dropzone = new Dropzone(this.fileFieldTarget.closest('form'), {
      paramName: this.fileFieldTarget.name,
      createImageThumbnails: false,
      disablePreviews: true,
      autoProcessQueue: true,
      acceptedFiles: '.jpg,.jpeg,.png',
      totaluploadprogress: (percent, totalBytes, totalBytesSent) => {
        this.progressBarTarget.style.width = `${percent}%`
        this.progressBarTarget.textContent = `${percent}%`
      },
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
      },
      success: (file) => {
        const event = new MessageEvent('message', { data: file.xhr.response })
        return this.element.dispatchEvent(event)
      },
      error: (file, errorMessage) => {
        if (!this.dropzone.options.acceptedFiles.includes(file.type)) {
          window.alert('Nicht unterstützter Dateityp. Bitte laden Sie nur jpg, jpeg oder png-Dateien hoch.')
        }
        this.dropzone.removeFile(file) // Die fehlerhafte Datei aus der Queue entfernen
      }

    })
  }

  disconnect () {
    Turbo.session.disconnectStreamSource(this.element)
  }
}
