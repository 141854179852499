import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect () {
    document.addEventListener('turbo:submit-start', this.handleSubmitStart)
    document.addEventListener('turbo:submit-end', this.handleSubmitEnd)

    this.originalSubmitValue = null

    this.showModal()
  }

  disconnect () {
    document.removeEventListener('turbo:submit-start', this.handleSubmitStart)
    document.removeEventListener('turbo:submit-end', this.handleSubmitEnd)
  }

  showModal () {
    this.element.classList.add('is-active')
    document.querySelector('html').classList.add('is-clipped')
  }

  hideModal () {
    this.element.classList.remove('is-active')
    document.querySelector('html').classList.remove('is-clipped')

    this.removeModal()
  }

  removeModal () {
    if (this.element.parentElement) { this.element.parentElement.src = null }
    this.element.remove()
  }

  handleSubmitStart = (e) => {
    this.originalSubmitValue = e.detail.formSubmission.submitter.value
    e.detail.formSubmission.submitter.value = 'Speichern...'
  }

  handleSubmitEnd = (e) => {
    e.detail.formSubmission.submitter.value = this.originalSubmitValue

    // Only close the modal if the request was a success and went to the actual form action.
    // The latter is useful in case of e.g. preview actions which submit the form to a different endpoint.
    if (e.detail.success && e.detail.formSubmission.location.href === e.target.action) {
      this.hideModal()

      // If the server response triggered a redirect, it went to the modal_body turbo frame.
      // Therefore, we have to manually trigger a _top visit with the new URL.
      // TODO: Check if the redirect should also be handled in the modal under certain conditions?
      if (e.detail.fetchResponse.response.redirected) {
        Turbo.visit(e.detail.fetchResponse.response.url)
      }
    }
  }
}
