import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = { src: String }

  connect () {
    console.log('fetching', this.srcValue)

    fetch(this.srcValue, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
