import { Controller } from '@hotwired/stimulus'
import { Sortable } from 'sortablejs'

export default class extends Controller {
  static values = {
    handleSelector: { type: String, default: '.drag-handle' },
    itemSelector: { type: String, default: '.draggable-item' }
  }

  static targets = ['positionField']

  connect () {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      draggable: this.itemSelectorValue,
      handle: this.handleSelectorValue,
      ghostClass: 'ghost',
      onUpdate: this.handleOrderUpdate
    })
  }

  handleOrderUpdate = (event) => {
    if (this.hasPositionFieldTarget) {
      this.positionFieldTargets.forEach((field, idx) => {
        field.value = idx
      })

      // If we changed positional field elements, we should also emit an input
      // event to make sure that e.g. a preview happens accordingly
      this.element.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  get items () {
    return this.element.querySelectorAll(this.itemSelectorValue)
  }
}
