import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import FlatpickrPredefinedRanges from '../../javascript/src/flatpickr-predefined-ranges'

export default class extends Controller {
  static targets = ['outputField', 'startDate', 'endDate']

  static predefinedRanges = {
    Heute: [new Date(), new Date()],
    Gestern: [dayjs().subtract(1, 'days').toDate(), dayjs().subtract(1, 'days').toDate()],
    'Letzte 30 Tage': [dayjs().subtract(29, 'days').toDate(), new Date()],
    'Aktueller Monat': [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
    'Letzter Monat': [
      dayjs().subtract(1, 'month').startOf('month').toDate(),
      dayjs().subtract(1, 'month').endOf('month').toDate()
    ],
    'Aktuelles Jahr': [
      dayjs().startOf('year').toDate(),
      new Date()
    ],
    'Letztes Jahr': [
      dayjs().subtract(1, 'year').startOf('year').toDate(),
      dayjs().subtract(1, 'year').endOf('year').toDate()
    ]
  }

  connect () {
    this.startDate = new Date(this.startDateTarget.value)
    this.endDate = new Date(this.endDateTarget.value)

    this.picker = flatpickr(this.outputFieldTarget, {
      plugins: [new FlatpickrPredefinedRanges()],
      mode: 'range',
      onChange: this.datesChanged,
      locale: German,
      dateFormat: 'd.m.Y',
      defaultDate: [this.startDate, this.endDate],
      weekNumbers: true,
      ranges: this.constructor.predefinedRanges,
      rangesOnly: false,
      rangesAllowCustom: true,
      rangesCustomLabel: 'Custom Range'
    })
  }

  datesChanged = (dates, dateString, _instance) => {
    if (dates.length === 2) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.setFieldValues()
    }
  }

  setFieldValues () {
    this.startDateTarget.value = this.startDate.toISOString()
    this.endDateTarget.value = this.endDate.toISOString()

    this.outputFieldTarget.disabled = true
    this.element.closest('form').requestSubmit()
    this.outputFieldTarget.disabled = false
  }
}
