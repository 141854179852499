import '@hotwired/turbo-rails'
import 'chartjs-adapter-date-fns'
import { de } from 'date-fns/locale'
import Chartkick from 'chartkick'
import 'chartkick/chart.js'
import 'controllers'
import 'channels'
import 'src/global_listeners'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import embed from 'vega-embed'
import './channels'

// Initialize vega charts
window.vegaEmbed = embed
window.dispatchEvent(new Event('vega:load'))

Sentry.init({
  dsn: 'https://133becad8a744faf8e40fe440ea0de51@scharfe-ente.snaque.com/4',
  environment: process.env.RAILS_ENV,
  allowUrls: [/snaque\.(com|de)/],

  integrations: [
    new BrowserTracing({
      tracingOrigins: ['*.snaque.com']
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05
})

Chartkick.options = {
  download: true,
  library: {
    responsive: true,
    scales: {
      x: {
        adapters: {
          date: {
            locale: de
          }
        }
      }
    }
  }
}

require.context('images', true)
