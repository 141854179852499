import { Controller } from '@hotwired/stimulus'

/**
 * Shows one of the element's ancestors when the controller is connected,
 * hides it when it's disconnected.
 * Accepts a selector to specify the ancestor to be shown/hidden
 */
export default class extends Controller {
  static values = { selector: { type: String, default: 'div' } }

  connect () {
    this.targetOnConnect = this.element.closest(this.selectorValue)
    this.targetOnConnect.style.display = 'block'
  }

  disconnect () {
    this.targetOnConnect.style.display = 'none'
  }
}
