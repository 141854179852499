import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'
import { Popover } from 'bootstrap'
import { CommonDOMRenderer } from 'render-jsx/dom'
import * as Routes from '../../../javascript/routes'
import { I18n } from 'i18n-js'
import translations from '../../../javascript/locales.json'
import { parameterizeString } from '../../../javascript/src/helpers'

const i18n = new I18n(translations)
i18n.locale = 'de'

export default class extends Controller {
  static targets = ['tagList']
  static values = {
    category: String,
    matchingType: String,
    tagClass: String
  }

  connect () {
    const tagClass = this.tagClassValue

    this.tagify = new Tagify(this.tagListTarget, {
      addTagOnBlur: false,
      dropdown: {
        position: 'text',
        enabled: 1 // show suggestions dropdown after 1 typed character
      },
      duplicates: false,
      enforceWhitelist: false,
      templates: {
        tag (tagData, tagify) {
          const renderer = new CommonDOMRenderer()

          return renderer.render(
            <tag
              title={tagData.title || tagData.value}
              contenteditable='false'
              spellcheck='false'
              tabIndex={this.settings.a11y.focusableTags ? 0 : -1}
              class={[this.settings.classNames.tag, tagData.matchingType, 'tag', tagClass, 'is-medium']}
              {...this.getCustomAttributes(tagData)}
            >
              <x title='' class={this.settings.classNames.tagX} role='button' aria-label='remove tag' />
              <div>
                <span class={[this.settings.classNames.tagText]}>
                  {tagData[this.settings.tagTextProp] || tagData.value}
                </span>
              </div>
            </tag>
          ).target.outerHTML
        }
      },
      transformTag: (tagData) => {
        tagData.name = tagData.value
      },
      trim: true
    })

    this.tagify.on('input', this.tagsChanged).on('edit:input', this.tagsChanged)
  }

  addTag = () => {
    this.tagify.addEmptyTag({ matchingType: this.matchingTypeValue, category: this.categoryValue })
  }

  /**
   * Loads matching tags from the server on user input (new tags and edited tags)
   * @param e
   */
  tagsChanged = (e) => {
    this.tagify.whitelist.length = 0 // reset current whitelist
    this.tagify.loading(true).dropdown.hide.call(this.tagify) // show the loader animation

    const { matchingType, category } = e.detail.tag.__tagifyTagData
    const inputValue = e.detail.data.newValue

    this
      .loadMatchingTags(category, inputValue)
      .then(tags => {
        const tagObjects = tags.map(tag => {
          return { category: tag.category, matchingType, value: tag.name, name: tag.name }
        })

        // Add the newly loaded tags to the dropdown suggestions and keep the data
        // the user already added.
        this.tagify.whitelist.push(...tagObjects, ...this.tagify.value)
        this.tagify.loading(false).dropdown.show.call(this.tagify, e.detail.data.newValue)
      })
  }

  loadMatchingTags = (category, input) => {
    return fetch(Routes.admin_tags_path({ category, q: input, format: 'json' }))
      .then(response => response.json())
  }
}
