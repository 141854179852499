import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'icon']
  static values = {
    iconDefault: { type: String, default: 'content_paste' },
    iconCopied: { type: String, default: 'inventory' }
  }

  copy (event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value).then(() => {
      this.#setCopiedIcon()
      setTimeout(() => {
        this.#setOriginalIcon()
      }, 3000)
    })
  }

  selectAll (event) {
    event.preventDefault()
    event.target.setSelectionRange(0, event.target.value.length)
  }

  #setCopiedIcon () {
    this.iconTarget.textContent = this.iconCopiedValue
  }

  #setOriginalIcon () {
    this.iconTarget.textContent = this.iconDefaultValue
  }
}
