import { Controller } from '@hotwired/stimulus'
import { CommonDOMRenderer } from 'render-jsx/dom'
import { ref } from 'render-jsx/common'

/**
 * Adds indicator elements to show the current and maximum allowed length in input elements.
 *
 * @example
 *   <input type="text" data-input-maxlength-target="input" maxlength="20" />
 */
export default class extends Controller {
  static targets = ['input']

  inputTargetConnected (element) {
    this.attachIndicatorElement(element)

    element.addEventListener('input', this.updateIndicatorElement)
  }

  inputTargetDisconnected (element) {
    element.removeEventListener('input', this.updateIndicatorElement)
  }

  attachIndicatorElement = (input) => {
    const renderer = new CommonDOMRenderer()

    input.maxLengthIndicator = ref()

    const indicator = renderer.render(
      <div class='maxlength-indicator' _ref={input.maxLengthIndicator}>{`${input.value.length}/${input.maxLength}`}</div>
    ).target

    input.insertAdjacentElement('afterend', indicator)
  }

  updateIndicatorElement = (event) => {
    const input = event.target
    const indicator = input.maxLengthIndicator

    indicator.$.textContent = `${input.value.length}/${input.maxLength}`
  }
}
