import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'

export default class extends Controller {
  static targets = ['dateField']

  connect () {
    this.picker = flatpickr(this.dateFieldTarget, {
      altInput: true,
      mode: 'single',
      locale: German,
      enableTime: false,
      dateFormat: 'Y-m-d',
      minDate: this.dateFieldTarget.dataset.minimum,
      maxDate: this.dateFieldTarget.dataset.maximum,
      altFormat: 'd.m.Y'
    })
  }

  disconnect () {
    if (this.picker) {
      this.picker.destroy()
    }
  }
}
