import { Controller } from '@hotwired/stimulus'

/**
 * Refreshes the turbo frame it is assigned to periodically
 */
export default class extends Controller {
  static values = { interval: { type: Number, default: 60 } }

  connect () {
    this.interval = setInterval(() => {
      this.element.reload()
    }, this.intervalValue * 1000)
  }

  disconnect () {
    clearInterval(this.interval)
  }
}
