import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'
import MaterialIconData from '@material-symbols/metadata/versions.json'
import { CommonDOMRenderer } from 'render-jsx/dom'
import { ref } from 'render-jsx/common'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = ['iconField', 'iconPreview', 'trigger']
  static values = { filterText: { type: String, default: '' } }

  initialize () {
    this.setFilterText = debounce(this.setFilterText, 500).bind(this)
  }

  connect () {
    this.icons = ref()
    this.renderer = new CommonDOMRenderer()
    this.availableIcons = Object.keys(MaterialIconData)

    this.iconSelector = new Popover(this.triggerTarget, {
      content: this.popoverContent(this.renderer),
      html: true,
      container: this.element
    })
  }

  popoverContent (renderer) {
    return renderer.render(
      <div>
        <div class='mb-3'>
          <input type='text' placeholder='Suchen...' oninput={this.setFilterText} class='form-control' />
        </div>
        <div class='row icons' _ref={this.icons}>
          {this.renderIcons(renderer)}
        </div>
      </div>
    ).target
  }

  renderIcons (renderer) {
    return this.filteredIcons().map(icon => {
      return renderer.render(
        <div class='col'>
          <button type='button' class='btn btn-snaque-gradient mb-1 mr-1' onclick={() => { this.selectIcon(icon) }}>
            <span class='material-symbols-rounded kiosk-icon' title={icon}>{icon}</span>
          </button>
        </div>
      ).target
    })
  }

  selectIcon (icon) {
    this.iconFieldTarget.value = icon
    this.iconPreviewTarget.textContent = icon
    this.iconSelector.hide()
    this.iconFieldTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }

  filterTextValueChanged () {
    this.icons?.$.replaceChildren(...this.renderIcons(this.renderer))
  }

  setFilterText (event) {
    this.filterTextValue = event.target.value
  }

  filteredIcons () {
    return this.availableIcons.filter(icon => icon.search(this.filterTextValue) > -1)
  }
}
