import dayjs from 'dayjs'

function FlatpickrPredefinedRanges () {
  return function (fp) {
    const rangesNav = document.createElement('ul')
    rangesNav.className = 'nav flex-column flatpickr-predefined-ranges'

    const pluginData = {
      ranges: typeof fp.config.ranges !== 'undefined' ? fp.config.ranges : {},
      rangesOnly: typeof fp.config.rangesOnly === 'undefined' || fp.config.rangesOnly,
      rangesAllowCustom: typeof fp.config.rangesAllowCustom === 'undefined' || fp.config.rangesAllowCustom,
      rangesCustomLabel: typeof fp.config.rangesCustomLabel !== 'undefined' ? fp.config.rangesCustomLabel : 'Custom Range',
      rangesNav,
      rangesButtons: {}
    }

    /**
     * @param {string} label
     * @returns HTML Element
     */
    const addRangeButton = function (label) {
      const button = document.createElement('button')
      button.type = 'button'
      button.className = 'nav-link btn btn-link'
      button.innerText = label

      pluginData.rangesButtons[label] = button

      const item = document.createElement('li')
      item.className = 'nav-item d-grid'

      item.appendChild(pluginData.rangesButtons[label])

      pluginData.rangesNav.appendChild(item)

      return pluginData.rangesButtons[label]
    }

    /**
     * Loop the ranges and check for one that matches the selected dates, adding
     * an active class to its corresponding button.
     *
     * If there are selected dates and a range is not matched, check for a custom
     * range button and set it to active.
     *
     * If there are no selected dates or a range is not matched, check if the
     * rangeOnly option is true and if so hide the calendar.
     *
     * @param {Array} selectedDates
     */
    const selectActiveRangeButton = function (selectedDates) {
      let isPredefinedRange = false
      const current = pluginData.rangesNav.querySelector('.active')

      if (current) {
        current.classList.remove('active')
      }

      if (selectedDates.length > 0) {
        const startDate = dayjs(selectedDates[0])
        const endDate = selectedDates.length > 1 ? dayjs(selectedDates[1]) : startDate
        for (const [label, range] of Object.entries(pluginData.ranges)) {
          if (startDate.isSame(dayjs(range[0]), 'day') && endDate.isSame(dayjs(range[1]), 'day')) {
            pluginData.rangesButtons[label].classList.add('active')
            isPredefinedRange = true
            break
          }
        }
      }

      if (selectedDates.length > 0 &&
        !isPredefinedRange &&
        Object.prototype.hasOwnProperty.call(pluginData.rangesButtons, pluginData.rangesCustomLabel)
      ) {
        pluginData.rangesButtons[pluginData.rangesCustomLabel].classList.add('active')
        fp.calendarContainer.classList.remove('flatpickr-predefined-ranges-only')
      } else if (pluginData.rangesOnly) {
        fp.calendarContainer.classList.add('flatpickr-predefined-ranges-only')
      }
    }

    return {
      onOpen () {
        const predefinedWidth = fp.calendarContainer.querySelector('ul.flatpickr-predefined-ranges').offsetWidth
        const calendarWidth = fp.calendarContainer.querySelector('.flatpickr-innerContainer').offsetWidth

        fp.calendarContainer.style.width = `${predefinedWidth + calendarWidth}px`
      },

      /**
       * Loop the ranges and add buttons for each range which a click handler to set the date.
       * Also adds a custom range button if the rangesAllowCustom option is set to true.
       */
      onReady (selectedDates) {
        for (const [label, range] of Object.entries(pluginData.ranges)) {
          addRangeButton(label).addEventListener('click', function () {
            const start = dayjs(range[0]).toDate()
            const end = dayjs(range[1]).toDate()

            if (!start) {
              fp.clear()
            } else {
              fp.setDate([start, end], true)
            }

            fp.close()
          })
        }

        if (pluginData.rangesNav.children.length > 0) {
          if (pluginData.rangesOnly && pluginData.rangesAllowCustom) {
            const customButton = addRangeButton(pluginData.rangesCustomLabel)
            customButton.addEventListener('click', function () {
              const current = pluginData.rangesNav.querySelector('.active')
              if (current) {
                current.classList.remove('active')
              }
              customButton.classList.add('active')
              fp.calendarContainer.classList.remove('flatpickr-predefined-ranges-only')
            })
          }

          fp.calendarContainer.prepend(pluginData.rangesNav)
          fp.calendarContainer.classList.add('flatpickr-has-predefined-ranges')
          // make sure the right range button is active for the default value
          selectActiveRangeButton(selectedDates)
        }
      },

      /**
       * Make sure the right range button is active when a value is manually entered
       *
       * @param {Array} selectedDates
       */
      onValueUpdate (selectedDates) {
        selectActiveRangeButton(selectedDates)
      }
    }
  }
}

export default FlatpickrPredefinedRanges
